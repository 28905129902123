<template>
  <v-hover v-slot="{ hover }" class="ml-n6">
    <div class="d-flex">
      <div style="min-width: 25px">
        <v-icon v-if="hover" max-width="25" height="20" @click="setFilter()">
          mdi-magnify</v-icon
        >
      </div>
       <template v-if="(typeof row['n_current_step']!='undefined' && row['n_current_step']> 0) || (typeof row['n_endtimeunix']!='undefined' && row['n_endtimeunix']!= 0) ">
            <router-link :to="usedLink + row['n_id'] + '&n_id_key=' + row['n_id_key']">{{
                row[header]
                }}</router-link>
       </template>
       <template v-else>
        <v-menu open-on-hover top offset-y min-width="350">
            <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on">
                    <div class="d-flex">
                        <div class="mr-2">
                            {{row[header]}}
                        </div>
                    </div>
                </a>
            </template>
            <v-list flat dense >
                <div class="subtitle-2 primary white--text">
                    <span class="ml-2">Testcase Info</span>
                </div>
                <div class="caption ml-5 mr-5 mb-5 mt-5">
                    <div>The testcase results is being prepared, so please be patient</div>
                </div>
            </v-list>
        </v-menu>
       </template>

    </div>
  </v-hover>
</template>
<script>
export default {
  props: ["row", "header", "currentTable"],
  data:function(){
    return {
      dialog: false,
    };
  },
  computed: {
    usedLink() {
      let link = "?f=testing&f2=testInfo&function=getInfo&n_id=";
      if (
        typeof this.currentTable["header"][this.header].usedLink != "undefined"
      ) {
        link = this.currentTable["header"][this.header].usedLink;
      }

      return link;
    },
  },
  methods: {
    showInfoDetails(){
      this.showInfo= true;
    },
    setFilter() {
      this.$set(this.currentTable, "searchValue", this.row[this.header]);
    },
  },
};
</script>